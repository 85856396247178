<template>
    <Navbar />
    <router-view />
</template>


<script>
import Navbar from './components/Navbar.vue';

export default {

    components: {
        Navbar
    },

    data() {
        return {

        }
    }
}
</script>

<style>
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: sans-serif;
	scroll-behavior: smooth;
	outline: none;
	-webkit-tap-highlight-color: transparent;
}



</style>
